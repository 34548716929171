<template>
  <div class="article-container" v-loading="loading">
    <div class="email-container">
      <div class="p-8 pb-4">
        <p class="text-2xl font-bold mb-2">{{ article.title || "-" }}</p>
        <p class="publisher-name">{{ article.user_nickName || "-" }}</p>
      </div>
      <div class="email-input px-8 py-6">
        <div v-if="!user_email" class="emailc">
          <div class="tip">
            Please input your email to add the into your personal AI knowledge
            base and receive periodic reminders of its key takeaways via emails.
          </div>
          <el-input
            class="mt-4"
            v-model="email"
            placeholder="Please input your email"
          ></el-input>
          <div class="mt-8 flex items-center justify-center">
            <div class="submit-btn cursor-pointer" @click="submit">Submit</div>
          </div>
        </div>
        <div v-else-if="user_email && success" class="emailc">
          <p class="success-tip">Successful!</p>
          <div class="tip">
            You have added {{ article.title }} into your personal AI knowledge
            base and receive periodic reminders of its key takeaways via emails.
            <div class="mt-8 flex items-center justify-center">
              <div class="login-btn cursor-pointer" @click="doLogin">Login</div>
            </div>
          </div>
        </div>
        <div v-else class="emailc">
          <div class="tip">
            {{ message }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserEmail } from "../utils/store";
import {
  submitUser,
  archiveArticleAndReimind,
  getArticle,
  createLoginPinCode,
} from "../api/reff";
export default {
  data() {
    return {
      user_email: getUserEmail(),
      email: "",
      success: false,
      loading: false,
      article_id: this.$route.query.id,
      message: "",
      article: {},
    };
  },
  mounted() {
    if (this.user_email) {
      this.loading = true;
      submitUser({
        user_email: this.user_email,
      }).then((res) => {
        if (res.data.code === 0) {
          let user_id = res.data.data._id.$id;
          this.archive(user_id);
        } else {
          this.message = "Failed";
          this.loading = false;
        }
      });
    }
    this.getArticle();
  },
  methods: {
    gotoInputPwd() {
      this.$router.push({
        path: "/login/pwd",
        query: {
          email: this.user_email,
        },
      });
    },
    doLogin() {
      createLoginPinCode(this.user_email, "user").then((res) => {
        this.$message.success("OTP sent,Please check your email");
        this.gotoInputPwd();
      });
    },
    getArticle() {
      getArticle({
        article_id: this.article_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.article = res.data.data;
        }
      });
    },
    submit() {
      let reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (!reg.test(this.email)) {
        this.$message.error("Please input the correct email format");
        return;
      }
      this.user_email = this.email;
      this.loading = true;
      submitUser({
        user_email: this.email,
      }).then((res) => {
        if (res.data.code === 0) {
          let user_id = res.data.data._id.$id;
          this.archive(user_id);
        } else {
          this.message = "Failed";
          this.loading = false;
        }
      });
    },
    archive(user_id) {
      this.loading = true;
      archiveArticleAndReimind({
        user_id: user_id,
        article_id: this.article_id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.success = true;
          } else {
            this.message = res.data.msg;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.article-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background-image: url("../assets/articleBg.png");
  background-repeat: no-repeat;
  background-size: cover;
  .email-container {
    width: 800px;
    background: rgba(254, 250, 247, 1);
    border-radius: 24px;
    border: 1px solid #ffe5d9;
    .publisher-name {
      font-size: 20px;
      font-weight: 700;
      color: #ec652b;
    }
  }
  .email-input {
    width: 100%;
    border-radius: 24px;
    background: #f4f4f4;
  }
  .tip {
    font-size: 20px;
    line-height: 32px;
    color: #1a202c;
  }
  .success-tip {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 12px;
    color: #ec652b;
  }
  .submit-btn {
    width: 300px;
    height: 48px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f4a101;
    color: white;
    border-radius: 16px;
  }
  .login-btn {
    width: 300px;
    height: 48px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ec652b;
    color: white;
    border-radius: 16px;
  }
}
</style>
